:root {
  --background: white;
  --text-primary: black;
  --text-secondary: #ffe4c4;
  --accent: #000000;

}
[data-theme='dark'] {
  --background: #061734;
  --text-primary: #c2d0d4;
  --text-secondary: #000000;
  --accent: #ffe4c4;
  --subtle: #D77A61;

}
.main {
  color: var(--text-primary);
  background-color: var(--background);
  transition: all .2s;
}

body
{
    background-color: #060F28;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Regular_0.ttf) format('truetype');
}



@font-face {
    font-family: 'consolas';
    src: local('Gilroy'), url(../assets/fonts/Consolas.ttf);
  }

::selection {
    color: black;
    background: bisque;

}

.MuiCheckbox-colorPrimary {
    color: primary;
}

.document
{
    display: inline-block;
    margin: 10px;
}


.main
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 2%;
    margin: 0 auto;
    box-sizing:border-box;
}

.left
{
    color: var(--subtle);
    width: 15%;
    align-self: flex-start;
    position: sticky;
    top: 20px;
    overflow: hidden;
    margin: 0px;
    text-align: center;
    font-family: consolas;
}


.left h2 {
    font-size: 26.5px;
    margin-top: 45px;
}

.left h3 {
    font-size: 17px;
    font-weight: 500;
    margin-top: -10px;
    margin-bottom: 45px;
}

.left a
{
    padding: 10px;
    text-decoration: none;
    color: var(--text-primary);
    float: left;
    font-weight: 500;
    font-size: large;
}

.left a:hover {
    background-color: var(--text-primary);
    color: var(--background);
    font-weight: bolder;
}
.left ul {
    color: var(--text-primary);
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin-right: 60px;
}

.left img
{
    object-fit: cover;
    width: 200px;
    height:200px;
    border-radius: 50%;
}

.mid
{
    margin-left: 50px;
    width: 50%;
}

.darkButton
{
    float: left;
}

.line
{
    height: 9px;
    background-color: var(--text-primary);
}



.projectContainer img
{
    text-align: center;
    height: auto;
}

.projectContainer {
    margin: 5px;
    position: relative;
    display: inline-block;
    opacity: 1;
}


.overlay {
    background-color: #ffe4c4;
    color: black; 
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .2s ease;
  }

.projectInfo {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 20px;
}


.projectContainer:hover img
{
    background-color: #ffe4c4;
}


.projectContainer:hover .overlay {
    opacity: 1;
}

.Poster
{
    display: flex;
    width: 100%;
}


.Poster img 
{
    width: 100%;
}

iframe
{
    margin: 10px;
}

.Assets
{
    text-align: center;
}


.MuiCheckbox-root .MuiSvgIcon-root {
    color: var(--text-primary);
}

@media (max-width: 1100px) {
   .left
   {
    width: 100%;
    position: relative;
    margin: 0;
   }
   
   .left ul
   {
    display: flex;
    justify-content: center;
   }

.main
{
    display: inline-block;
    width: 100%;
    padding: 3%;
    margin: 0 auto;
    box-sizing:border-box;
}

.mid
{
    margin-left: 10px;
    width: 100%;
    overflow: auto;
}

.projectContainer
{
    overflow: hidden;
    object-fit: cover;
}
}

@media (min-width: 1100px) and (max-width: 1800px) {

    .mid
    {
        float: right;
        width: 58%;
    }

    .main
    {
        display: flex;
    }

    .left
    {
        width: 25%;
    }

}

@media (max-width: 1080px) {

    .left ul
    {
        margin-right: 50px;
    }


    .left .line
    {
        margin-left: 15%;
        width: 70%;
    }


}
